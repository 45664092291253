@import url('../fonts/fonts.css');

$font-pn-regular: 'Proxima Nova Rg', sans-serif;
$font-pn-semibold: 'Proxima Nova Semibold', sans-serif;
$font-pn-bold: 'Proxima Nova Bold', sans-serif;


$red: #FF1C20;
$blue: #0071BC;

$trans: all .25s ease;



body {
  font-family: $font-pn-regular;
  font-size: 17px;
  line-height: 20px;
  color: #111111;
}

a {
  outline: none;
}

.red_text {
  color: $red;
}

.blue_text {
  color: #0071BC;
}

//HEADER

header {
  position: fixed;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #2D2D2D;
  z-index: 100;

  .col_right {
    text-align: right;
  }

  nav {
    height: 100%;

    ul {
      height: 100%;
      margin-bottom: 0;
      padding-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;

      li {
        margin-right: 15px;

        &:last-of-type {
          margin-right: 0;
        }

        a {
          font-size: 13px;
          color: #fff;
          border-bottom: 2px solid transparent;
          transition: $trans;
          text-decoration: none !important;
          text-transform: uppercase;

          &:hover {
            color: $red;
            border-bottom: 2px solid $red;
          }
        }
      }
    }
  }
}



.header_phone_wrap {

  a {
    display: block;
    font-family: $font-pn-bold;
    font-size: 30px;
    color: #fff;
    text-decoration: none !important;
    transition: $trans;
    
    &:hover {
      color: $red;
    }

    img {
      margin-right: 15px;
    }
  }
  
  time {
    font-size: 20px;
    color: #fff;
  }
}

//POPAP FORM

.popap-form {
  position: relative;
  max-width: 360px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 33px;
  padding-left: 56px;
  padding-right: 56px;
  padding-bottom: 42px;
  background-color: #2D2D2D;

  .mfp-close {
    color: $red;
  }

  h4 {
    margin-bottom: 18px;
    font-family: $font-pn-bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }

  p {
    margin-bottom: 18px;
    text-align: center;
    color: #fff;
  }

  button[type=submit] {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-family: $font-pn-bold;
    font-size: 18px;
    line-height: 64px;
    color: #fff;
    border: 0;
    border-bottom: 3px solid #930B07;
    border-radius: 36px;
    text-align: center;
    cursor: pointer;
    transition: $trans;
    outline: none;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    box-shadow: 0px 15px 30px 0px rgba(204,0,0,0.1);
    background: linear-gradient(to bottom, rgba(255,51,0,1) 0%, rgba(204,0,0,1) 100%);


    &:hover {
      background-color: lighten($red, 6%);
      background: linear-gradient(to bottom, rgba(204,0,0,1) 0%, rgba(255,51,0,1) 100%);
    }
  }

  input {
    height: 50px;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 50px;
    padding-right: 20px;
    border-radius: 25px;
    line-height: 25px;
    outline: none;
    font-size: 18px;
    color: #4F4F4f;
    border: 2px solid #C4C4C4;
    background: #fff url("../img/icons/input_phone.png") no-repeat center left 10px;
    transition: $trans;

    &:hover, &:focus {
      border: 2px solid darken(#C4C4C4, 30%);
    }
  }
}


//SLOGAN

.slogan {
  padding-top: 110px;
  padding-bottom: 10px;

  h2 {
    font-family: $font-pn-bold;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    text-align: center;
  }
}

//ABOUT

.about {
  height: 412px;
  background: url("../img/worker_bg.jpg") no-repeat center;
  background-size: cover;
  overflow-y: hidden;

  .col_left {
    position: relative;
  }

  .about_worker {
    position: absolute;
    bottom: 70px;
    left: 77px;
    padding: 10px;
    background: rgba(255,255,255,.8);

    p {
      margin-bottom: 0;
      font-size: 20px;
      line-height: 24px;
    }
  }

  h2 {
    margin-top: 60px;
    padding-right: 100px;
    font-family: $font-pn-bold;
    line-height: 55px;
    text-transform: uppercase;
    font-size: 46px;
    text-align: right;
  }
}

//ORDER FORM

.order_form {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #2D2D2D;

  h3 {
    margin-bottom: 30px;
    font-family: $font-pn-bold;
    font-size: 27px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
  }

  form {
    max-width: 310px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  input {
    height: 50px;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 50px;
    padding-right: 20px;
    border-radius: 25px;
    line-height: 25px;
    outline: none;
    font-size: 18px;
    color: #4F4F4f;
    border: 2px solid #C4C4C4;
    background: #fff url("../img/icons/input_phone.png") no-repeat center left 10px;
    transition: $trans;

    &:hover, &:focus {
      border: 2px solid darken(#C4C4C4, 30%);
    }
  }

  button {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-family: $font-pn-bold;
    font-size: 18px;
    line-height: 64px;
    color: #fff;
    border: 0;
    border-bottom: 3px solid #930B07;
    border-radius: 36px;
    text-align: center;
    cursor: pointer;
    transition: $trans;
    outline: none;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    box-shadow: 0px 15px 30px 0px rgba(204,0,0,0.1);
    background: linear-gradient(to bottom, rgba(255,51,0,1) 0%, rgba(204,0,0,1) 100%);


    &:hover {
      background-color: lighten($red, 6%);
      background: linear-gradient(to bottom, rgba(204,0,0,1) 0%, rgba(255,51,0,1) 100%);
    }
  }
}

//WHY WE

.why_we {
  padding-top: 90px;
  padding-bottom: 90px;

  h2 {
    position: relative;
    margin-bottom: 60px;
    font-family: $font-pn-bold;
    font-size: 34px;
    text-transform: uppercase;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: -10px;
      margin-left: -150px;
      display: block;
      height: 2px;
      width: 300px;
      background-color: $red;
    }
  }

  .why_item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
      float: left;
      margin-right: 15px;
    }
  }

  .why_desc {
    //padding-top: 30px;
    padding-right: 30px;

    p {
      margin-bottom: 0;
      font-size: 17px;
      line-height: 27px;
    }
  }
}

//HOW WE WORK

.how_we_work {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #2D2D2D;

  h2 {
    position: relative;
    margin-bottom: 60px;
    font-family: $font-pn-bold;
    font-size: 34px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: -10px;
      margin-left: -185px;
      display: block;
      height: 2px;
      width: 370px;
      background-color: $red;
    }
  }

  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 70px;
    padding-left: 0;
    list-style: none;

    li {
      position: relative;
      margin-right: 94px;
      max-width: 140px;

      &:last-of-type {
        margin-right: 0;

        &:after {
          content: "";
        }
      }

      &:after {
        content: url("../img/icons/how_work_arrow.png");
        position: absolute;
        top: 52px;
        right: -94px;
      }
    }

    p {
      font-family: $font-pn-bold;
      line-height: 20px;
      font-size: 16px;
      text-align: center;
      color: #FFFFFF;
    }
  }

  .red_circle {
    height: 140px;
    width: 140px;
    margin-right: 0;
    margin-bottom: 9px;
    border-radius: 50%;
    background-color: $red;
    text-align: center;
    line-height: 140px;
  }

  h3 {
    margin-bottom: 30px;
    font-family: $font-pn-bold;
    font-size: 27px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }

  form {
    max-width: 310px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  input {
    height: 50px;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 50px;
    padding-right: 20px;
    border-radius: 25px;
    line-height: 25px;
    outline: none;
    font-size: 18px;
    color: #4F4F4f;
    border: 2px solid #C4C4C4;
    background: #fff url("../img/icons/input_phone.png") no-repeat center left 10px;
    transition: $trans;

    &:hover, &:focus {
      border: 2px solid darken(#C4C4C4, 30%);
    }
  }

  button {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-family: $font-pn-bold;
    font-size: 18px;
    line-height: 64px;
    color: #fff;
    border: 0;
    border-bottom: 3px solid #930B07;
    border-radius: 36px;
    text-align: center;
    cursor: pointer;
    transition: $trans;
    outline: none;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    box-shadow: 0px 15px 30px 0px rgba(204,0,0,0.1);
    background: linear-gradient(to bottom, rgba(255,51,0,1) 0%, rgba(204,0,0,1) 100%);

    &:hover {
      background-color: lighten($red, 6%);
      background: linear-gradient(to bottom, rgba(204,0,0,1) 0%, rgba(255,51,0,1) 100%);
    }
  }
}

//SERVICES

.services {
  padding-top: 90px;
  padding-bottom: 90px;

  h2 {
    position: relative;
    margin-bottom: 60px;
    font-family: $font-pn-bold;
    font-size: 34px;
    text-transform: uppercase;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: -10px;
      margin-left: -215px;
      display: block;
      height: 2px;
      width: 430px;
      background-color: $red;
    }
  }

  .service_item {
    margin-bottom: 40px;
    padding-top: 35px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    border: 1px solid #e9e9e9;
    transition: $trans;
    border-radius: 10px;

    &:hover {
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
    }

    .img_wrap {
      display: block;
      margin-bottom: 20px;
    }

    h4 {
      margin-bottom: 20px;
      //font-family: $font-pn-bold;
      font-size: 22px;
      text-align: center;
      line-height: 20px;
      //font-weight: 700;
      color: #0071BC;
    }

    .price {
      margin-bottom: 20px;
      font-family: $font-pn-bold;
      font-size: 17px;
      text-align: center;
      line-height: 20px;
      font-weight: 700;
      color: $red;
    }
  }

  .order_btn {
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    padding-left: 35px;
    padding-right: 35px;
    line-height: 50px;
    font-family: $font-pn-bold;
    color: #fff;
    text-decoration: none !important;
    background-color: #0071BC;
    text-align: center;
    transition: $trans;
    border-radius: 25px;

    &:hover {
      background-color: lighten(#0071BC, 5%);
    }
  }
}

//QUALIFICATION

.qualification {
  padding-top: 20px;
  padding-bottom: 20px;
  background: url("../img/qualification_bg.jpg") no-repeat center;
  background-size: cover;

  &>.container {
    position: relative;
  }

   h3 {
     margin-bottom: 20px;
     padding-left: 100px;
     font-size: 17px;
     text-transform: uppercase;
     line-height: 20px;
     font-weight: 700;
   }

  ul {
    margin-left: 100px;
    margin-right: 370px;
    margin-bottom: 0;
    padding-left: 10px;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 5px;
      padding-left: 30px;

      i {
        position: absolute;
        left: 0;
        margin-right: 10px;
        color: #0071BC;
      }
    }
  }

  img {
    position: absolute;
    right: 145px;
    bottom: -20px;
    z-index: 10;
  }
}

//REVIEWS

.reviews {
  padding-top: 90px;
  padding-bottom: 90px;

  h2 {
    position: relative;
    margin-bottom: 60px;
    font-family: $font-pn-bold;
    font-size: 34px;
    text-transform: uppercase;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: -10px;
      margin-left: -215px;
      display: block;
      height: 2px;
      width: 430px;
      background-color: $red;
    }
  }

  img {
    margin-bottom: 30px;
  }
}

//SOCIAL

.social {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #2D2D2D;

  h3 {
    margin-bottom: 20px;
    line-height: 26px;
    font-size: 22px;
    text-align: center;
    font-family: $font-pn-bold;
    color: #fff;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
      margin-right: 30px;

      a {
        color: #fff;
        transition: $trans;

        &:hover {
          color: $red;
        }

        i {
          font-size: 60px;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

//FOOTER

footer {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 3px solid $red;
  background-color: #2D2D2D;

  .row {
    align-items: center;
  }

  .left_col {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .footer_logo {
      margin-right: 10px;
      border-bottom: none !important;
    }

    h3 {
      font-family: $font-pn-bold;
      font-size: 29px;
      line-height: 35px;
      color: #fff;
    }
    
    p {
      margin-bottom: 5px;
      font-size: 12px;
      line-height: 14px;
      color: #fff;
    }

    a {
      color: #fff;
      border-bottom: 1px solid #fff;
      transition: $trans;
      text-decoration: none !important;

      &:hover {
        color: $red;
        border-bottom: 1px solid transparent;
      }
    }
  }

  .header_phone_wrap {
    text-align: right;
  }
}


                  //CATALOG PAGE

//DIRECTORY SELECTION

.directory_selection {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #dadada;

  h2 {
    position: relative;
    margin-bottom: 60px;
    font-family: $font-pn-bold;
    font-size: 34px;
    text-transform: uppercase;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: -10px;
      margin-left: -215px;
      display: block;
      height: 2px;
      width: 430px;
      background-color: $red;
    }
  }

  .catalog_item {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 40px;
    padding-top: 35px;
    padding-bottom: 30px;
    text-align: center;
    border: 1px solid #e9e9e9;
    transition: $trans;
    border-radius: 10px;
    background-color: #fff;

    &:hover {
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
    }

    .img_wrap {
      margin-bottom: 20px;
    }

    h3 {
      margin-bottom: 30px;
      font-family: $font-pn-semibold;
      font-size: 30px;
      text-align: center;
      line-height: 20px;
      //color: #0071BC;
    }
  }

  a {
    display: block;
    width: 100%;
    max-width: 200px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    font-family: $font-pn-bold;
    font-size: 18px;
    line-height: 64px;
    color: #fff;
    border: 0;
    border-bottom: 3px solid #930B07;
    border-radius: 36px;
    text-align: center;
    cursor: pointer;
    transition: $trans;
    outline: none;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    text-decoration: none !important;
    box-shadow: 0px 15px 30px 0px rgba(204,0,0,0.1);
    background: linear-gradient(to bottom, rgba(255,51,0,1) 0%, rgba(204,0,0,1) 100%);

    &:hover {
      background-color: lighten($red, 6%);
      background: linear-gradient(to bottom, rgba(204,0,0,1) 0%, rgba(255,51,0,1) 100%);
    }
  }
}

//BIMETALLIC RADIATORS

.radiators_wrap {
  padding-top: 90px;
  padding-bottom: 90px;

  h2 {
    position: relative;
    margin-top: 30px;
    margin-bottom: 60px;
    font-family: $font-pn-bold;
    font-size: 34px;
    text-transform: uppercase;
    text-align: center;

    &:nth-of-type(2){
      margin-top: 120px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: -10px;
      margin-left: -215px;
      display: block;
      height: 2px;
      width: 430px;
      background-color: $red;
    }
  }

  .radiator_row {
    margin-bottom: 70px;
    padding: 30px 20px;
    border: 3px solid #e9e9e9;
    border-radius: 10px;

    h3 {
      margin-bottom: 40px;
      font-family: $font-pn-bold;
      font-size: 28px;
      text-transform: uppercase;
      text-align: center;

      span {
        border-bottom: 2px solid $red;
      }
    }
  }

  .radiator_desc {

    h5 {
      margin-bottom: 15px;
      font-family: $font-pn-semibold;
      font-size: 30px;
      line-height: 30px;
      color: $red;
      text-align: center;
    }

    .price {
      margin-bottom: 20px;
      font-family: $font-pn-bold;
      font-size: 40px;
      text-align: center;
      border: 2px solid $red;
      line-height: 60px;
      border-radius: 30px;
    }

    a {
      display: block;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      font-family: $font-pn-bold;
      font-size: 18px;
      line-height: 64px;
      color: #fff;
      border: 0;
      border-bottom: 3px solid #930B07;
      border-radius: 36px;
      text-align: center;
      cursor: pointer;
      transition: $trans;
      outline: none;
      text-transform: uppercase;
      letter-spacing: 1.6px;
      box-shadow: 0px 15px 30px 0px rgba(204,0,0,0.1);
      background: linear-gradient(to bottom, rgba(255,51,0,1) 0%, rgba(204,0,0,1) 100%);
      text-decoration: none !important;

      &:hover {
        background-color: lighten($red, 6%);
        background: linear-gradient(to bottom, rgba(204,0,0,1) 0%, rgba(255,51,0,1) 100%);
      }
    }

    ul.main_characteristics {
      margin-bottom: 0;
      list-style: none;

      li {
        position: relative;
        margin-bottom: 10px;
        font-size: 20px;

        i {
          position: absolute;
          top: 3px;
          left: -18px;
          font-size: 12px;
          color: $red;
        }
      }
    }

    ul.additional_characteristics {
      margin-top: 40px;
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;
      border: 1px solid darken(#e9e9e9, 10%);;
      border-radius: 10px;
      overflow: hidden;

      li {
        margin-bottom: 0;
        border-bottom: 1px solid darken(#e9e9e9, 10%);

        &.open {

          h6 {
            background-color: darken(#e9e9e9, 10%);
          }

          i {
            transform: rotate(180deg);
          }
        }

        &:last-of-type {
          border-bottom: 0;
        }

        &:hover {

          h6 {
            background-color: darken(#e9e9e9, 10%);
          }
        }

        h6 {
          position: relative;
          margin-bottom: 0;
          padding: 10px 30px 10px 15px;
          font-family: $font-pn-semibold;
          font-size: 18px;
          border-bottom: 1px solid #e9e9e9;
          background-color: #e9e9e9;
          cursor: pointer;
          transition: $trans;

          i {
            position: absolute;
            right: 10px;
            top: 14px;
            font-size: 15px;
          }
        }

        p {
          padding: 10px 15px;
          display: none;
        }
      }
    }
  }

  .slick_slider_wrap img {
    margin-left: auto;
    margin-right: auto;
  }
}

                            //THANKS PAGE

#thanks_page .about {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.thanks_wrap {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(0,0,0,.8);
  text-align: center;
  
  h1 {
    font-family: $font-pn-bold;
    font-size: 50px;
    color: #fff;
  }
  
  p {
    //font-family: $font-pn-semibold;
    font-size: 24px;
    color: #fff;
  }

  a {
    color: $red;
    text-decoration: none !important;
    transition: $trans;

    &:hover {
      color: $blue;
    }
  }
}









//.checkbox_wrap {
//
//  .ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
//    border-radius: 0;
//  }
//
//  .ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited, a.ui-button, a:link.ui-button, a:visited.ui-button, .ui-button {
//    margin-right: 0;
//    margin-bottom: 0;
//    padding: 0;
//    color: #333;
//    font-size: 14px;
//    border: none !important;
//    background: none;
//    outline: none !important;
//    box-shadow: none !important;
//  }
//
//  .ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus {
//    border: none;
//    background: none;
//    outline: none !important;
//  }
//
//  .ui-checkboxradio-label .ui-icon-background {
//    border: 1px solid $blue;
//    background-color: #fff;
//    background-image: none;
//  }
//
//  .ui-state-active .ui-icon, .ui-button:active .ui-icon {
//    background: url("../img/icons/check_checkbox.png") no-repeat center top 2px, linear-gradient(to right, rgba(0, 120, 190, 1) 0%, rgba(0, 158, 193, 1) 100%);
//    box-shadow: none;
//  }
//
//  .ui-icon {
//    height: 14px;
//    width: 14px;
//  }
//
//  .ui-checkboxradio-icon-space {
//    display: none;
//  }
//
//  .checkbox_desc {
//    padding-left: 6px;
//    font-size: 13px;
//    display: inline-block;
//    max-width: 225px;
//  }
//
//  .ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited, a.ui-button, a:link.ui-button, a:visited.ui-button, .ui-button {
//    position: relative;
//    top: -18px;
//  }
//}


